import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  alternate?: boolean;
  noShadow?: boolean;
  id?: string;
}

const Container = ({ children, className, alternate, id, noShadow }: Props) => (
  <div
    id={id}
    className={classnames(
      styles.background,
      alternate && styles.alternate,
      noShadow && styles.noShadow
    )}
  >
    <div className={classnames(styles.content, className)}>{children}</div>
  </div>
);

export default Container;
