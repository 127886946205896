import React from 'react';
import { useController, useForm } from 'react-hook-form';

import { ReactComponent as LoaderSVG } from '../loader/loader.svg';

import styles from './styles.scss';

interface Props {
  onSubmit: (values: ContactFormFields) => void;
  busy: boolean;
}

export const QUERY_TYPES = Object.freeze({
  UNKNOWN: 0,
  GENERAL: 1,
  LAST_WILL: 2,
});

const ContactForm = ({ onSubmit, busy }: Props) => {
  const {
    control,
    register,
    formState: { isSubmitting, errors, isValid },
    handleSubmit,
  } = useForm<ContactFormFields>({ mode: 'onChange' });

  const { field: queryTypeField } = useController({
    name: 'queryTypeId',
    control,
  });

  const isLastWillQuery = queryTypeField.value === QUERY_TYPES.LAST_WILL;

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.field}>
        <label htmlFor="queryType">How can we help?</label>
        <select
          id="queryType"
          placeholder="Select an option"
          onChange={(e) => {
            queryTypeField.onChange(parseInt(e.currentTarget.value));
          }}
        >
          <option value={QUERY_TYPES.GENERAL}>General Enquiry</option>
          <option value={QUERY_TYPES.LAST_WILL}>Last Will and Testament</option>
        </select>
      </div>
      <div className={styles.field}>
        <label htmlFor="name">Name and Surname</label>
        <input id="name" type="text" {...register('name', { required: true })} />
      </div>
      <div className={styles.field}>
        <label htmlFor="name">Email</label>
        <input id="email" type="email" {...register('email', { required: true })} />
        {errors.email && <span className={styles.validation}>Invalid email address.</span>}
      </div>
      {isLastWillQuery ? (
        <div className={styles.field}>
          <label htmlFor="contactNumber">Contact Number</label>
          <input
            id="contactNumber"
            type="tel"
            {...register('contactNumber', { required: isLastWillQuery })}
          />
        </div>
      ) : (
        <div className={styles.field}>
          <label htmlFor="query">Your query</label>
          <textarea id="query" {...register('query', { required: !isLastWillQuery })} />
        </div>
      )}
      <button type="submit" disabled={!isValid || isSubmitting}>
        {busy ? <LoaderSVG className={styles.loader} /> : 'Submit'}
      </button>
    </form>
  );
};

export default ContactForm;
