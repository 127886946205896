import React from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

import MenuItems from './menu-items';

import LogoUrl from '../../assets/logo.png';
import styles from './styles.scss';

interface Props {
  onMenuClick: () => void;
}

const Nav = ({ onMenuClick }: Props) => (
  <nav className={styles.container}>
    <div className={styles.menu}>
      <Link className={styles.logo} to="/">
        <img src={LogoUrl} alt="logo" />
      </Link>
      <button className={styles.burger} onClick={onMenuClick}>
        <FaBars />
      </button>
      <div className={styles.desktopNav}>
        <MenuItems />
      </div>
    </div>
  </nav>
);

export default Nav;
