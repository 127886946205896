import React, { useState } from 'react';

// components
import Container from '../container';
import ContactForm, { QUERY_TYPES } from '../contact-form';
import ContactInfo from './info';

// assets
import WaveUrl from './wave.svg';
import styles from './styles.scss';

const Contact = () => {
  const [sending, setSending] = useState(false);
  const [querySent, setQuerySent] = useState(false);
  const [error, setError] = useState(false);

  const showFeedback = querySent || error;
  return (
    <div id="contact" className={styles.container}>
      <img className={styles.wave} src={WaveUrl} alt="wave" />
      <Container alternate noShadow className={styles.contact}>
        {!showFeedback && <h2>Have a query?</h2>}
        {!showFeedback && (
          <ContactForm
            busy={sending}
            onSubmit={(values) => {
              setSending(true);
              const isLastWillEnquiry = values.queryTypeId === QUERY_TYPES.LAST_WILL;
              const lastWillQuery = `
                Hi.

                I need assistance with my Last Will and Testament.

                Email Address: ${values.email}
                Contact Number: ${values.contactNumber}
              `;

              fetch('https://mailer.francoislaubscher.dev', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  from: values.email,
                  to: isLastWillEnquiry ? 'deidre@assent.co.za' : 'dhusselmann@bonsano.co.za',
                  subject: isLastWillEnquiry
                    ? `Bon Sano: Last Will and Testament Enquiry - ${values.name}`
                    : `Website Enquiry - ${values.name}`,
                  body: isLastWillEnquiry ? lastWillQuery : values.query,
                }),
              })
                .then((r) => {
                  setSending(false);
                  setError(!r.ok);
                  setQuerySent(r.ok);
                })
                .catch(() => {
                  setSending(false);
                  setError(true);
                });
            }}
          />
        )}
        {showFeedback && (
          <div className={styles.feedback}>
            <span className={styles.heading}>
              {error ? 'We were unable to send your query.' : 'Thank you.'}
            </span>
            <span>
              {error
                ? 'Please contact us directly using the details below.'
                : `We'll get back to you.`}
            </span>
          </div>
        )}
        <h2 className={styles.contactHeading}>Contact Us</h2>
        <ContactInfo />
      </Container>
    </div>
  );
};

export default Contact;
