import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.scss';

interface Props {
  onMenuItemClick?: () => void;
}

const MenuItems = ({ onMenuItemClick }: Props) => (
  <>
    <Link onClick={onMenuItemClick} className={styles.link} to="/#services">
      Our Services
    </Link>
    <Link onClick={onMenuItemClick} className={styles.link} to="/#faq">
      FAQ
    </Link>
    <Link onClick={onMenuItemClick} className={styles.link} to="/#contact">
      Contact Us
    </Link>
  </>
);

export default MenuItems;
