import React from 'react';

import styles from './styles.scss';

interface Props {
  href: string;
  children: React.ReactNode;
}

const Banner = ({ href, children }: Props) => (
  <a href={href} download className={styles.banner}>
    {children}
  </a>
);

export default Banner;
