import React from 'react';

import styles from './styles.scss';

const ContactInfo = () => (
  <div id="contact-info" className={styles.contactInfo}>
    <div className={styles.info}>
      <div className={styles.text}>
        <span className={styles.heading}>Address</span>
        <a href="https://goo.gl/maps/1Ye8XBWxc3CjbhuF6" rel="noreferrer" target="_blank">
          Unit 8D Old Dutch Square
          <br />
          Corner of Bill Bezuidenhout Avenue
          <br />
          and Old Paarl Road
          <br />
          Bellville, 7530
        </a>
      </div>
      <div className={styles.text}>
        <span className={styles.heading}>Telephone</span>
        <a href="tel:+27219454363">(021) 945 4363</a>
      </div>
      <div className={styles.text}>
        <span className={styles.heading}>Fax</span>
        <a href="tel:+27219454363">(021) 945 4397</a>
      </div>
      <div className={styles.text}>
        <span className={styles.heading}>Email</span>
        <a href="mailto:admin@bonsano.co.za">admin@bonsano.co.za</a>
      </div>
    </div>
    <iframe
      className={styles.map}
      title="map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1655.7923809690026!2d18.643241957917102!3d-33.90034653930333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc50f4f1277c2f%3A0x68b3e9ff51b7020d!2sOld%20Dutch%20Square!5e0!3m2!1sen!2sza!4v1591949087088!5m2!1sen!2sza"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen={false}
      aria-hidden="false"
    />
  </div>
);

export default ContactInfo;
