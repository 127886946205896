import React from 'react';

import LoaderUrl from './loader.svg';

import styles from './styles.scss';

const Loader = () => (
  <div className={styles.container}>
    <img src={LoaderUrl} alt="loader" />
  </div>
);

export default Loader;
