import React from 'react';

import styles from './styles.scss';

const Footer = () => (
  <div className={styles.copy}>
    <span>Bon Sano PTY Ltd is an authorised financial services provider (FSP 20416)</span>
    <span>Copyright 2021 Bon Sano PTY Ltd - All rights reserved</span>
  </div>
);

export default Footer;
