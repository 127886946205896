import React, { useState } from 'react';
import classnames from 'classnames';

// components
import Contact from '../contact';
import DownloadBanner from '../banner/download';
import Footer from '../footer';
import Nav from '../nav';
import Sidebar from '../sidebar';

import styles from './styles.scss';

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className={classnames(styles.layout, showSidebar && styles.sideBarVisible)}>
      <div className={classnames(styles.content, showSidebar && styles.sideBarVisible)}>
        <DownloadBanner href="/downloads/MemberLetter2023.pdf">
          From 1 December 2023, Sanlam will be the underwriter of your funeral policy.
          <br />
          Click here to read more.
        </DownloadBanner>
        <Nav onMenuClick={() => setShowSidebar(!showSidebar)} />
        {children}
        <Contact />
        <Footer />
      </div>
      <Sidebar visible={showSidebar} onCloseClick={() => setShowSidebar(false)} />
    </div>
  );
};

export default Layout;
